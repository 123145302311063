<template>
	<div class="library-wrap">

		<!-- 检索内容 -->
		<el-row :gutter="20" style="padding:0 40px;" v-if="viewer.showFiles.length > 0">
			<ul class="similar-list">
				<li v-for="liItems in viewer.showFiles" :key="liItems.id" >
				<h3  @click="handleRowClickeEvent(liItems,null)"><span v-html="liItems.content?liItems.content:liItems.name"></span></h3>
				<dl>
					<dd>
						<div class="tiem-and-content">
							<div v-if="liItems.documentContentList" class="text">
							<div class="content-item" v-for="(item,index) in liItems.documentContentList" :key="index"  @click="handleRowClickeEvent(liItems,item.pageIndex)">
								<span style="font-weight:bold;margin-right:10px;">[第 {{item.pageIndex+1}} 页] </span>
								<span class="contents"  v-html="item.content"></span>
							</div>
							</div>
						</div>
						<div class="just">
						<p>
							<span class="time">{{liItems.createDate | date("yyyy-MM-dd") }}</span>
							<span class="time marginLeft">{{liItems.ownerName}}</span>
							<span class="time marginLeft">{{liItems.suffix || "文件夹"}}</span>
							<span class="time marginLeft">{{liItems.size | byteSize}}</span>
						</p>
						</div>
					</dd>
				</dl>
				</li>
			</ul>
		</el-row>
    
		<!-- 阅读 -->
		<ReadFile ref="readFile" />
	</div>
</template>

<script> 
import ReadFile from "@/components/readFile.vue"
import { shareAPI } from 'liankong-ui-api' 
import { Store } from '@/utils/LocalCache.js'
export default {
	props:{
		viewer:{
			type:Object,
			default:{}
		},
	},
  	components: {ReadFile},
	methods:{
		handleRowClickeEvent(row,pageIndex) {
			this.readDocEvent(row,pageIndex)
		},
		// 阅读
		readDocEvent(row,pageIndex) {
			if(row.documentId != null){
				row.id = row.documentId
			}
			shareAPI.getReaderUrl('', row.documentId || row.id, row.documentId,row.name, '')
			.then(({ code, data, message }) => {
				if (code == 0) {
					//获取到阅读地址，跳转阅读服务
					if(Store.get("myPreference")){
						if(Store.get("myPreference").reader_model == '1'){
							window.open(this.$options.filters['readerUrl'](data.url+'&pageIndex='+pageIndex));
						}else{
							this.$refs['readFile'].showReadFile(this.$options.filters['readerUrl'](data.url+'&pageIndex='+pageIndex))
						}
					}else{
						this.$refs['readFile'].showReadFile(this.$options.filters['readerUrl'](data.url+'&pageIndex='+pageIndex))
					}
				} else if(code == 1001) {
					// this.$message.error('权限不足，请申请阅读');
					this.$refs.multipleTable.toggleRowSelection(row, true);
					this.$store.commit("cart/resetRequest")
					this.multipleSelection.forEach(mulItem=>{
						this.$store.commit("cart/push", {
							viewerKey: this.cartViewKey,
							doc: mulItem,
						})
						this.showFiless.filter(item=>{
							if(item.id==mulItem.id){
							this.$set(mulItem,'selected',true)
							}
						})
						this.$nextTick(() => this.$forceUpdate())
					})
					// this.$store.commit("cart/push", {
					//     viewerKey: this.cartViewKey,
					//     doc: row,
					//   })
					this.$store.commit('setCartShow', true)

				} else {
					this.$message.error(message || '您不能查看当前文件') 
				}
			})
		},
	}
}
</script>
<style> .key{
    color: red;
    border-bottom:1px solid red;
  }
  .content {
    color: red;
  }</style>
<style lang='less' scoped>
  @import "../../styles/index";
  .demo-form-inline {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin-bottom: 10px;
    padding-top: 50px;
    h3 {
      margin: 0;
      padding: 0;
    }
    /deep/ .el-form-item {
      height: 40px;
      margin: 0 15px;

      .el-form-item__content {
        height: 45px;
        
        .el-input {
          width: 550px;
          height: 40px;
         .el-input-group__prepend{
          background-color: #fff;
          }

          .el-input__inner {
            height: 100%;
          }
        }
        .el-select {
          .el-select__tags {
            transform:translateY(0)
          }
          .el-input {
          width: 100px;
          height: 40px;
          .el-input__inner {
            height: 100%;
          }
        }
        }
        .el-date-editor  {
          width: 180px;
        }
        .el-input-group__append {
          background: #409EFF;
          color: #fff;
          font-size: 18px;
          padding: 0 25px;
        }
      }
    }
    .el-button {
      height: 39px;
    }
  }
  .radio-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    /deep/ .el-tabs {
      .el-tabs__header {
        margin: 0px;
        .el-tabs__nav-wrap::after {
          height: 0px;
        }
        .el-tabs__active-bar {
          height: 3px;
        }
      }
      .el-tabs__item {
        width: 60px;
        padding: 0;
        text-align: center;
      }
    }
  }
  .no-data-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: -1%;
      margin-right: 4%;
      img {
          width: 380px;
          height: 280px;
      }
      span {
          color: #bdc1cd;
          font-size: 16px;
          margin-top: 15px;
          font-weight: 100px;
      }
  }
  .user-avatar {
    vertical-align: middle;
    margin-right: 8px;
  }

  .btn {
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fast {
    /deep/ .el-dialog {
      .el-dialog__header {
        display: flex;
        justify-content: center;

        .el-dialog__headerbtn {
          display: none;
        }
      }

      .el-dialog__body {
        display: flex;
        justify-content: center;

        .el-image {
          width: 50%;
        }
      }

      .el-dialog__footer {
        display: flex;
        justify-content: center;
      }
    }
  }
  .document-card {
      height: 295px;
      margin: 0;
      padding: 10px 12px;
      // background: #F5F7FA;
      background: var(--themecolor);
  }
  .document {
    // height: 330px;
    height: 200px;
    cursor: pointer;
    user-select: none;
    position: relative;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 10px;
    // background: pink;
    /deep/ .el-card__body {
      padding: 10px;
    }
  }
  // .document:hover {
  //   border-radius: 5px;
  //   box-shadow: 0px 2px 16px 0px rgba(18, 55, 100, 0.2);
  // }

  .document-top {
    position: relative;
    // padding: 10px;
    height: 200px;
    overflow: hidden;
    .img-tip {
      position: absolute;
      right: 0px;
      bottom: 0px;
      width: 18px;
      height: 18px;
    }
    .apply-tips {
      position: absolute;
      left: 0px;
      bottom: 3px;
      span {
        border: 1px solid #ccc;
        padding: 3px;
        border-radius: 5px;
        font-size: 13px;
        color: #D04C38
      }
    }
  }

  .document-top img {
    width: 100%;
    height: 100%;
  }

  .document-middle {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: @h6;
    // padding: 10px 20px;
    font-size: 14px;
    color:#333;
    margin-top: 10px;
    p {
      margin: 0;
      padding: 0;
      height: 18px;
      line-height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }
  }
  .star-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 0 5px 0;
    span {
      font-size: 13px;
    }
    /deep/ .el-checkbox {
      .el-checkbox__label {
        padding-left: 5px;
      }
    }
  }

  .document-bottom {
    // padding-bottom: 30px;
    display: flex;
    justify-content: space-evenly;

    .btn{
      font-size: 16px;
    }
  }

  .select{
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .pagination {
    width: 1200px;
    margin: 0 auto;
    margin-top:20px;
    .el-pagination {
      text-align: left;
      margin-bottom: 10px;
    }
  }
  /deep/ .el-table {
    .file-box {
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        img {
            width: 25px;
            height: 25px;
            margin-right: 5px;
            display: block;
        }
        >span {
            display: block;
            width: 240px;
            height: 25px;
            overflow: hidden;
            text-overflow: ellipsis;
            // display: -webkit-box;
            // -webkit-line-clamp: 1;
            white-space: nowrap;
            // -webkit-box-orient: vertical;
        }
        .apply-tips {
          position: absolute;
          right: 30px;
          top: 1px;
          span {
            border: 1px solid #ccc;
            padding: 2px;
            border-radius: 5px;
            font-size: 12px;
            color: #D04C38
          }
        }
    }
    .el-table__header-wrapper {
        .has-gutter {
            tr th{
                border:none;
                background: #F2F2F2;
                color: #666;
            }
        }
    }
    .el-table__body-wrapper {
        tr td {
            border:none;
            padding: 12px 0;
        }
    }
    &::before {
        height: 0px;
    }
}
.similar-list{
  margin: 0;
  padding: 0;
  height:calc(100vh - 280px);
  overflow: auto;
  li {
    list-style: none;
    margin: 0 0 30px 0;
    cursor: pointer;
    h3 {
      margin: 0 0 10px 0;
      padding: 0;
      color:#2440b3;
      font-size: 18px;
      font-weight: 400;
     .key{
          color:red;
    }
      span {
        border-bottom: 1px solid #2440B3;
         
      }
      // border-bottom: 1px solid #2440B3;
    }
    dl {
      margin: 0;
      padding: 0;
      display: flex;
      dt {
        height: 90px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      dd {
        flex: 1;
        margin: 0;
        padding: 0;
        .tiem-and-content {
          overflow: hidden;

          .text {
            color: #666;
            font-size: 13px;
            
            .content-item{
              margin-bottom: 10px;
            }
            .contents{
              color: #999;
              font-size: 13px;
              line-height: 20px;
            }
          }
        }
        .just {
          display: flex;
          justify-content: space-between;
          p{
            margin: 0;
          }
          .apply {
               span {
              border: 1px solid #ccc;
              padding: 3px;
              border-radius: 5px;
              font-size: 13px;
              color: #D04C38;
              margin-right:10px;
              box-sizing: border-box;
            }
          }
        }
      .time {
          color:#9195A3;
          font-size: 13px;
        }
        .marginLeft {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>