<template>
  <div >
    <el-dialog title="上传文件" :close-on-click-modal="false"  :visible.sync="visible" width="500px" :append-to-body='true' :before-close="clearData">
      
    <div >
      <div class="">
       
          <!-- 拖拽上传 -->
          <div  >
            <div class="elUpload-box">

            <el-upload
              ref='elUpload'
              drag
              action="none"
              :auto-upload="false"
              :on-change="onUploadChanged"
              :show-file-list="true"
              :multiple='true'>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>

              <el-button @click="toChoiceFile('file')" size='small'>选择文件</el-button>
              <!-- <el-button @click="toChoiceFile('img')" size='small'>选择图片</el-button> -->
              <el-button @click="toChoiceFile('folder')" size='small'>选择文件夹</el-button>
            </div>
          </div>

          <!-- 配置参数 -->
          <paramConfig v-show="!this.$isRunInsert"  :viewerKey='viewerKey' :infos="uploadInfos" ref='paramConfig'></paramConfig>
      </div>
      
    
      
      
      
    </div>
    <span slot="footer" class="dialog-footer" >
      <div>
        <el-button type="primary" @click="startUpload">开始上传</el-button>
        <!-- <el-button v-if="active == 1" type="primary" @click="doNext" >下一步</el-button>
        <el-button v-if="active == 2" type="primary" @click="doPre" >上一步</el-button> -->
      </div>
    </span>

      
      
      <!-- <span slot="footer" class="dialog-footer" v-if="select">
        <el-button @click="doreset">重新选择</el-button>
        <el-button type="primary" @click="$refs['upload'].doSubmit()"
          >上 传</el-button
        >
      </span>
      <div class="upload">
        <i class="el-icon-upload2" @click="handleQuickCommand('upload')"></i>
        <i class="el-icon-share" @click="handleQuickCommand('share')"></i>
      </div> -->
    </el-dialog>
    <!-- <div class="upload">
      <i class="el-icon-upload2" @click="show()"></i>
      <i class="el-icon-share" @click="handleQuickCommand('share')"></i>
    </div> -->
  </div>
</template>

<script>
import paramConfig from '../components/Upload/paramConfig.vue'
export default {
	props: {
		uploadInfos: {
		type: Object
		}
	},
	components: {
		paramConfig
	},
	data() {
		return {
			visible: false,
			select: false,
			viewerKey:'my',
			active: 1,
      statusText: {
        success: "上传成功",
        error: "上传出错了",
        uploading: "上传中...",
        paused: "暂停中...",
        waiting: "等待中...",
        cmd5: "计算文件MD5中...",
      },
		};
	},
	methods: {
		show(vkey) {
			this.viewerKey = vkey;
			this.visible = true;
		},
		selected(file) {
			this.select = true;
		},
		// refreshOrgDocList() {
		// 	this.$emit('refreshOrgDocListData')
		// },
		doNext() {
			this.active++
		},
		doPre(){
			this.active = 1;
		},
		clearData(){
			this.visible = false;
			this.active = 1;
      this.$refs['paramConfig'].uploadForm = {
        sign: null,
        miji: "",//密级
        isShare:false,//是否共享
        isPublic: false, // 是否公开
        fileTags: [],//标签
      }
		},
    // 选择文件
    toChoiceFile(type){
       this.$ebus.$emit('openUploader',{
          type:type,
          params:this.$refs['paramConfig'].uploadForm,
          viewerKey:this.viewerKey,
          uploadInfos:this.uploadInfos
       })
      //  this.clearData()
    },
    onUploadChanged(file,List){
       this.$ebus.$emit('openUploader',{
          type:'folder',
          params:this.$refs['paramConfig'].uploadForm,
          viewerKey:this.viewerKey,
          uploadInfos:this.uploadInfos,
          file:List
       })
        this.$refs.elUpload.clearFiles()
      //  this.clearData()
    },
    startUpload(){
       this.$ebus.$emit('openUploader',{
          type:'start',
          params:this.$refs['paramConfig'].uploadForm,
          viewerKey:this.viewerKey,
          uploadInfos:this.uploadInfos,
       })
        this.clearData()
    }
	},
	created() {},
	mounted() {
    this.$ebus.$on('refreshOrgDocList', query => {
			this.$emit('refreshOrgDocListData')
		});
  },
};
</script>
<style lang='less' scoped>
.upload {
  font-size: 30px;
  position: fixed;
  right: 10px;
  bottom: 10px;
}
.upload i {
  padding: 5px;
  border: 1px solid #333;
  display: block;
}
.dialog-footer{
  clear: both;
}
.elUpload-box{
  text-align: center;
  /deep/.el-upload{
    margin-bottom: 15px;
  }
}
</style>