<template>
  <div class="params-box">
    <el-form  ref="uploadForm" :model="uploadForm" :rules="rules" @submit.native.prevent label-width="140px" size='small'>
      <el-form-item label="文件密级" prop="miji" v-if="viewerKey=='org'">
        <el-radio-group v-model="uploadForm.miji" size="small">
          <el-radio-button :label="item.value" v-for="item in miji" :key="item.value">{{item.label}}</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否在文档库显示：" v-if="viewerKey == 'my' || (viewerKey == 'org' && uploadForm.miji == 0 && uploadForm.miji != '')">
        <el-switch v-model="uploadForm.isShare"></el-switch>
      </el-form-item>
      <el-form-item label="文档内容保护：" v-if="uploadForm.isShare&&(viewerKey == 'my' ||  (viewerKey == 'org' && uploadForm.miji == 0 && uploadForm.miji != ''))">
        <el-radio-group v-model="uploadForm.isPublic">
          <el-radio :label="false">需申请可用</el-radio>
          <el-radio :label="true">公开</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="文件标签：" :loading='addTagState' v-if="viewerKey != 'org' && viewerKey != 'groupDoc'">
          <el-select v-model="uploadForm.fileTags" multiple filterable allow-create default-first-option placeholder="请选择文件标签"
            style="width: 100%;" size="small"
            @change="addTagData" ref='selectTags'>
              <el-option-group
                v-for="labelTagItem in tagTypeList"
                :key="labelTagItem.label"
                :label="labelTagItem.label">
                <el-option
                    v-for="item in labelTagItem.options"
                    :key="item.id"
                    :label="item.tagName"
                    :value="item.id">
                </el-option>
              </el-option-group>
          </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { dictAPI } from 'liankong-ui-api' 
export default {
  components: {},
  props: {
    viewerKey:{
      type:String,
      default:'my'
    },
    infos: {
      type: Object
    }
  },
  data () {
    return {
      uploadForm: {
        sign: null,
        miji: "",
        isShare:false,//是否共享
        isPublic: false, // 是否公开
         fileTags: [],
      },
      rules: {
        miji: [
          {required: true, message: "请设置文件密级",  trigger: 'change' }
        ]
      },
      tagTypeList: [],
      tableData: [],
      fxiedLabelDatas: [],
      addTagState:false
    }
  },
  computed: {
    viewer(){
      return this.$store.state.doc.viewer[this.viewerKey]
    },
    miji(){
      return this.$store.state.dict.dicts.miji
    }
  },
  watch: {},
  methods: {
        // 标签列表
        getTagAllList(){
            dictAPI.getTagList({
                pageNum: 1,
                pageSize: 999999
            }).then(res=>{
                if(res.code == 0) {
                    this.tableData = res.data;
                } else {
                    this.$message.error(res.message);
                }
            }).catch(error=>{
                this.$message.error(error.message);
            })
        },
        // 固定标签列表
        getFxiedLabelList(){
            dictAPI.getSystemLabelList({
                pageNum: 1,
                pageSize: 999999
            }).then(res=>{
                if(res.code == 0) {
                    this.fxiedLabelDatas = res.data;
                } else {
                    this.$message.error(res.message);
                }
            }).catch(error=>{
                this.$message.error(error.message);
            })
        },
        formatArrays(){
            setTimeout(()=>{
                this.tagTypeList = []
                let newObj = {
                    label: '系统标签',
                    options: this.fxiedLabelDatas
                }
                let newObj1 = {
                    label: '自定义标签',
                    options: this.tableData
                }
                this.tagTypeList.push(newObj, newObj1);
            }, 1000)
        },
        // 添加标签
        addTagData(){
            this.addTagState = true;
            this.uploadForm.fileTags.map((name,index) => {
            if(!this.filterArray(name)){
                dictAPI.addTagData({
                    tagName:name
                }).then(res=>{
                    if(res.code == 0) {
                        this.getTagAllList();
                        this.getFxiedLabelList();
                        this.formatArrays();
                        setTimeout(()=>{
                        this.uploadForm.fileTags.pop()
                        this.uploadForm.fileTags.push(res.data)
                        this.addTagState = false;
                        },1000)
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(error=>{
                        this.$message.error(error.message);
                })
            }
            })
            
        },
        filterArray(name){
            let flag = false
            this.tagTypeList.map(item => {
                item.options.map(ii => {
                if(ii.id == name){
                flag = true
                }
                })
            })
            return flag;
        },
  },
  mounted(){
        this.getTagAllList();
        this.getFxiedLabelList();
        this.formatArrays();
  }
}
</script>
<style lang='less' scoped>
.params-box{
  margin-top:15px;
}
</style>