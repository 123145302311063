<template>
  <el-dialog
    title="文件属性"
    :visible.sync="visible"
    :close-on-click-modal="false" 
    width="30%">
    <el-form label-width="100">
      <el-form-item label="文件名">
        <span>{{document.name}}</span>
      </el-form-item>
      <el-form-item label="大小">
        <span>{{document.size| byteSize}}</span>
      </el-form-item>
      <el-form-item label="类型">
        <span>{{document.type == 1 ?  document.suffix : "文件夹"}}</span>
      </el-form-item>
      <el-form-item label="上传日期">
        <span>{{document.createDate|date}}</span>
      </el-form-item>
       <el-form-item label="是否在文档库显示" v-show="isShare&&document.type == 1 && vkey == null">
        <el-switch v-model="document.shareFlag" :disabled="!(document.ownerId == $store.state.login.id)" :active-value="1" :inactive-value='0' ></el-switch>
      </el-form-item>
      <el-form-item label="文档内容保护" v-show="isShare&&document.shareFlag&&document.type == 1">
        <el-radio-group v-model="document.isPublic" :disabled="!(document.ownerId == $store.state.login.id)">
          <el-radio :label="0">需申请可用</el-radio>
          <el-radio :label="1">公开</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="标签" v-show="isShare && document.type == 1 && vkey == null">
        <div v-if="tagArrayList.length > 0">
          <el-tag style="margin-right: 5px;" type="info" v-for="(tagItems,index) in tagArrayList" :key="index">{{tagItems.tagName}}</el-tag>
        </div>
        <span v-else>暂无标签</span>
      </el-form-item>
    </el-form>

    <span slot="footer">
      <el-button type="primary" @click="doCommit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { dictAPI } from 'liankong-ui-api' 
export default {
  components: {},
  data () {
    return {
      visible: false,
      document: {},
      isShare:true,
      vkey: '',
      tagArrayList: [],
      beforeChangeData:{}
    }
  },
  computed: {
  },
  watch: {

  },
  methods: {
    show(value,vkey){
      this.vkey = vkey;
      if(vkey=='groupDoc'){
        this.isShare=false;
      }
      this.document = value;
      this.beforeChangeData = JSON.parse(JSON.stringify(value));
      this.share = (value.attrs && value.attrs["share"]) || false
      this.visible = true
      this.lookDocTagInfo();
    },
    doCommit(){
      // 如果是群组文档 或者 文件夹 则不调用修改详情接口，直接关闭弹框
      if(this.vkey=='groupDoc' || this.document.type == 0) {
          this.visible = false;
          return;
      }
      // 如果没有更改，则不调用接口直接关闭弹框
      if(this.beforeChangeData.shareFlag == this.document.shareFlag && this.beforeChangeData.isPublic == this.document.isPublic){
          this.visible = false;
          return;
      }
      let param = {
        description: this.document.description,
        documentId: this.document.id,
        tag: this.document.tag,
        miji: this.document.miji,
        shareFlag: this.document.shareFlag,
        isPublic: this.document.isPublic
      };
      let that = this;
      this.$store.dispatch('doc/updateDocumentOption',{
        // vkey:'my',
        vkey: this.vkey,
        option:param
      }).then(function (data) {
          if (data) {
            that.$message.success("修改成功!")
            that.visible = false;
          } else {
            that.$message.success("修改失败!")
            that.visible = false;
          }
        });
      
    },
    // 查看被分配的标签
    lookDocTagInfo(){
        this.tagArrayList = [];
        dictAPI.lookDocTag(this.document.id).then(res=>{
            if(res.code == 0) {
               this.tagArrayList = res.data;
            } else {
              this.$message.error(res.message);
            }
        })
    },
  },
  created () {
  },
  mounted () {
    
    
  },
}
</script>
<style lang='less' scoped>

</style>