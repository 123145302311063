import { render, staticRenderFns } from "./FileProps.vue?vue&type=template&id=613adb8a&scoped=true&"
import script from "./FileProps.vue?vue&type=script&lang=js&"
export * from "./FileProps.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "613adb8a",
  null
  
)

export default component.exports